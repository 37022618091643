<template>
    <!--components/CreateRunBookModal.vue-->
    <div class="modal modal-md" tabindex="-1" aria-labelledby="Create Runbook Modal Dialog" aria-hidden="true" ref="element_ref">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content collaboration-modal">
                <h5 class="modal-title" v-if="props.title != ''">{{ props.title }}</h5>
                    <div class="text-end modal-close">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                <div class="modal-body">
                    <!--<p style="text-align: center">{{ message }}</p>-->
                    <div class="options_container">
                        <div>
                        <button class="btn btn-outline-primary me-3" data-bs-dismiss="modal" @click="goToPage('/task-create-ai')">
                            <span>AI Generates</span>
                        </button>
                        <p class="help_text">AI generates a complete runbook</p>
                        </div>
                        <div>
                        <button class="btn btn-primary me-3" data-bs-dismiss="modal" @click="goToPage('/task-create')">
                            <span>AI Assists</span>
                        </button>
                        <p class="help_text">AI assists you to create a task</p>
                        </div>
                    </div>
                    <div id="help_text_container">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    const props = defineProps({
        title: {
            type: String,
            required: false,
            default: ""
        },
        message: {
            type: String,
            required: true
        },
        show_close: {
            type: Boolean,
            required: false,
            default: false
        },
        opacity: {
            type: String,
            required: false,
            default: '0%'
        },
        width: {
            type: String,
            required: false,
            default: '50%'
        }
    });
    const emit = defineEmits(["closeModal"]);
    
    const goToPage = async (page) => {
        await navigateToWrapper(page);
    }

    let element_ref = ref(null);
    onMounted(() => {
        // Here, we have to use a setTimeout because element_ref.value may not be readily available yet
        setTimeout(() => {
            hideTooltips(document.getElementsByTagName('body')[0]);
            var myModal = new bootstrap.Modal(element_ref.value, { backdrop: false });
            myModal.show();

            element_ref.value.addEventListener('hidden.bs.modal', function (event) {
                emit('closeModal');
            });

        }, 1);
    })

</script>

<style scoped>
.help_text {
  margin-top: 10px;
}
.options_container {
  display: flex;
}
.options_container > div {
  width: 50%;
  text-align: center;
}
</style>
