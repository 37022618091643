<template>
    <!--components/SignInBtn.vue-->
    <NuxtLink 
        to="/vlogin" 
        class="btn blue-btn" 
        no-prefetch
        :disabled="! page_mounted_state"
        :class="{'disabled':  ! page_mounted_state}" 
    >{{ button_label }}</NuxtLink>
</template>

<script setup>
let button_label = 'Sign in';
const page_mounted_state = usePageMountedState();
</script>

<style scoped>
.blue-btn {
    padding-top: 5px;
    padding-bottom: 5px;
    align-self: center;
    margin-bottom: 5px;
    margin: 0px 5px !important;
}
</style>